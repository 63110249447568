const header = {
  // all the properties are optional - can be left empty or deleted
  // homepage: 'https://github.com/VinoU',
  // title: '.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Vino Uthayakumar',
  role: 'Software Engineer',
  description:
    'Application Engineer skilled at technical leadership, communication and presentations. Experienced in full project life cycle from design to implementation to integration. Worked in multiple sectors, providing expertise across the banking, government and telecommunications service industries',
  resume: 'https://drive.google.com/file/d/1Nh-3P2LQVxgEMViiTSCFbUhhMjglaBss/view?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/vinouthayakumar/',
    github: 'https://github.com/VinoU',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Capco',
    description:
      'Capco operates at the intersection of business and technology by combining innovative thinking with unrivalled industry knowledge to deliver end-to-end data-driven solutions and fast-track digital initiatives for banking and payments, capital markets, wealth and asset management, insurance, and the energy sector.',
    stack: ['JAVA', 'GIT', 'MYSQL'],
    sourceCode: 'https://github.com',
    livePreview: 'https://www.capco.com/',
  },
  {
    name: 'CGI',
    description:
      'CGI is among the largest IT and business consulting services firms in the world. We are insights-driven and outcome-based to help accelerate returns on your IT and business investments. In all we do, our goal is to build trusted relationships through client proximity, providing industry and technology expertise to help you meet the needs of your customers and citizens. ',
    stack: ['SASS', 'Java', 'XML', 'Git'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'CGI',
    description:
      'CGI is among the largest IT and business consulting services firms in the world. We are insights-driven and outcome-based to help accelerate returns on your IT and business investments. In all we do, our goal is to build trusted relationships through client proximity, providing industry and technology expertise to help you meet the needs of your customers and citizens. ',
    stack: ['javascript', 'Adobe experince manager', 'Adobe LiveCycle'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },

  {
    name: 'Ministry government and consumer service',
    description:
      '',
    stack: ['Java', 'Adobe LiveCycle', 'C#'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Java',
  'CSS',
  'UNIX',
  'Adobe Experince Manager',
  'JavaScript',
  'C#',
  'MYSQL',
  'Docker',
  'SASS',
  'API/REST web services',
  'Git',
  'XML',
  'SoapUI',
  'Postman, Docker, Bitbucket',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'info@vinouthayakumar.com',
}

export { header, about, projects, skills, contact }
